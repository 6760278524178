<template lang="pug">

</template>

<script lang="ts">
import Vue from 'vue'
import ApplicationService from './../services/ApplicationService.js'

export default Vue.extend({
  async mounted() {
    const oldId = this.$route.params.oldId
    if (oldId) {
      const id = await ApplicationService.getUserFromOldId(oldId)
      this.$router.push('/annuaire/' + id)
    } else {
      this.$router.push('/')
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
